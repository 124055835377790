// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-kalpesh-js": () => import("../src/pages/about-kalpesh.js" /* webpackChunkName: "component---src-pages-about-kalpesh-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-preparation-before-you-go-before-you-go-js": () => import("../src/pages/preparation/before-you-go/BeforeYouGo.js" /* webpackChunkName: "component---src-pages-preparation-before-you-go-before-you-go-js" */),
  "component---src-pages-preparation-before-you-go-index-js": () => import("../src/pages/preparation/before-you-go/index.js" /* webpackChunkName: "component---src-pages-preparation-before-you-go-index-js" */),
  "component---src-pages-preparation-documentation-documentation-js": () => import("../src/pages/preparation/documentation/Documentation.js" /* webpackChunkName: "component---src-pages-preparation-documentation-documentation-js" */),
  "component---src-pages-preparation-documentation-index-js": () => import("../src/pages/preparation/documentation/index.js" /* webpackChunkName: "component---src-pages-preparation-documentation-index-js" */),
  "component---src-pages-preparation-exit-tourism-exit-tourism-js": () => import("../src/pages/preparation/exit-tourism/ExitTourism.js" /* webpackChunkName: "component---src-pages-preparation-exit-tourism-exit-tourism-js" */),
  "component---src-pages-preparation-exit-tourism-index-js": () => import("../src/pages/preparation/exit-tourism/index.js" /* webpackChunkName: "component---src-pages-preparation-exit-tourism-index-js" */),
  "component---src-pages-resources-js": () => import("../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-stories-kyoto-3-days-stories-itinerary-index-js": () => import("../src/pages/stories/kyoto-3-days-stories-itinerary/index.js" /* webpackChunkName: "component---src-pages-stories-kyoto-3-days-stories-itinerary-index-js" */),
  "component---src-pages-stories-kyoto-3-days-stories-itinerary-kyoto-js": () => import("../src/pages/stories/kyoto-3-days-stories-itinerary/Kyoto.js" /* webpackChunkName: "component---src-pages-stories-kyoto-3-days-stories-itinerary-kyoto-js" */),
  "component---src-pages-stories-osaka-3-days-stories-itinerary-index-js": () => import("../src/pages/stories/osaka-3-days-stories-itinerary/index.js" /* webpackChunkName: "component---src-pages-stories-osaka-3-days-stories-itinerary-index-js" */),
  "component---src-pages-stories-osaka-3-days-stories-itinerary-osaka-js": () => import("../src/pages/stories/osaka-3-days-stories-itinerary/Osaka.js" /* webpackChunkName: "component---src-pages-stories-osaka-3-days-stories-itinerary-osaka-js" */),
  "component---src-pages-stories-tokyo-3-days-stories-itinerary-index-js": () => import("../src/pages/stories/tokyo-3-days-stories-itinerary/index.js" /* webpackChunkName: "component---src-pages-stories-tokyo-3-days-stories-itinerary-index-js" */),
  "component---src-pages-stories-tokyo-3-days-stories-itinerary-tokyo-js": () => import("../src/pages/stories/tokyo-3-days-stories-itinerary/Tokyo.js" /* webpackChunkName: "component---src-pages-stories-tokyo-3-days-stories-itinerary-tokyo-js" */),
  "component---src-pages-stories-tokyo-last-days-index-js": () => import("../src/pages/stories/tokyo-last-days/index.js" /* webpackChunkName: "component---src-pages-stories-tokyo-last-days-index-js" */),
  "component---src-pages-stories-tokyo-last-days-last-days-js": () => import("../src/pages/stories/tokyo-last-days/LastDays.js" /* webpackChunkName: "component---src-pages-stories-tokyo-last-days-last-days-js" */)
}

